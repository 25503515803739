import ApiService from "@/core/services/api.service";

let url = process.env.VUE_APP_API_ADMIN;

class Zendesk {
  uploadFile(data) {
    return ApiService.post(url + "/zendesk/uploadFile", data);
  }

  createTicket(data) {
    return ApiService.post(url + "/zendesk/createTicket", data);
  }
}

export default new Zendesk();
